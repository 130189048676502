.post {
	h1 a {
		color: #000;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	time {
		padding: 0 0 20px 0;
		display: block;
	}

	.recipe-overview {
		padding: 0;
		margin: 0;

		svg {
			position: relative;
			top: 6px;
		}

		span {
			margin: 0 18px 0 3px;
		}

		li {
			list-style: none;
			padding: 0;
			margin: 0;

			@media #{$desktop} {
				display: inline-block;
			}
		}
	}

	.recipe-contents {
		@media #{$desktop} {
			display: flex;
		}

		ul {
			padding: 0;
			li {
				list-style-type: none;
				&:before {
					content: '\00bb';
					color: #ff4e4e;
					padding-right: 0.5em;
				}
			}
		}

		ol {
			padding: 0;
			li{
				counter-increment: step-counter;
				list-style-type: none;
				position: relative;
				margin-left: 34px;

				&:before {
					content: counter(step-counter);
					margin-right: 0px;
					background-color: #ff4e4e;
					color: white;
					/* padding: 1px 7px; */
					font-size: .7em;
					position: absolute;
					left: -34px;
					top: -2px;
					width: 25px;
					text-align: center;
				}
			}
		}
		.ingredients {
			width: 250px;
			padding: 0 43px 0 0;
		}

		.directions {
			@media #{$desktop} {
				flex: 1;
			}
		}
	}
}

.post + .post {
	margin: 65px 0 50px 0;
}

.sharing {
	margin: 50px 0 0 0;
	text-align: center;
	font-size: 0;

	a {
		text-decoration: none;
		text-align: center;
		position: relative;
		width: 56px;
		display: inline-block;
		height: 44px;
		line-height: 0;
		padding: 0;
		margin: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&:hover {
			&:before {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, .25);
				content: "";
			}
		}

		svg {
			fill: #fff;
		}

		&.email {
			background-color: #ff9d09;
		}

		&.facebook {
			background-color: #3b5998;
		}

		&.twitter {
			background-color: #55acee;
		}

		&.google-plus {
			background-color: #db4437;
		}

		&.youtube {
			background-color: #cd201f;
		}

		&.instagram {
			background-color: #f167f5;
		}

		&.linkedin {
			background-color: #0077b5;
		}

		&.pinterest {
			background-color: #C92228;
		}

		&.pinterest-icon:hover svg {
			fill: #bd081c;
		}
	}
}

.pagination {
	text-align: center;
	margin: 50px 0 0 0;
}

.take-me {
	float: right;
	.button {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.cta {
	text-align: center;
	padding: 50px 0 0 0;
}
